import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SingleCalendarEvents from 'react-single-calendar-events';
import './style.css';
import moment from 'moment';
import { loadDashboardEvents } from 'store/dashboard/actions';
import { Loading } from 'components/base';

function EventCalender() {
  const dispatch = useDispatch();

  const handleEventMonth = async (data) => {
    let eventDate = data.split(/[\s,]+/);
    let year = eventDate[eventDate.length - 1];
    const month = moment(Date.parse(data)).format('MM');

    await dispatch(loadDashboardEvents(year, month));
  };

  const { dashboardEventView, loading } = useSelector(
    (state) => state.dashboard
  );

  const options = {
    positionX: 'right',
    positionY: 'top',
    badge: 'circle',
    pattern: 'flat',
    fontSize: 12,
    border: true,
    presentOnly: true,
    accessibility: true,
    tooltip: true,
    tooltipPosition: 'top',
    tooltipTitle: true,
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='flex items-center justify-center'>
      <SingleCalendarEvents
        options={options}
        events={dashboardEventView}
        eventMonth={handleEventMonth}
      />
    </div>
  );
}

export default EventCalender;

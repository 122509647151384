import * as types from 'store/state/types';

const initialState = {
  filters: {
    query: '',
  },
  loading: false,
  statePage: 1,
  pageSize: 10,
  pageFirst: 0,
  leaves: [],
  files: null,
};

const stateReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PUSH_FILTER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        filters: action.payload,
      };

    case types.PUSH_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        statePage: action.payload,
      };
    case types.PUSH_PAGE_SIZE_SUCCESS:
      return {
        ...state,
        loading: false,
        pageSize: action.payload,
      };

    case types.PUSH_PAGE_FIRST_SUCCESS:
      return {
        ...state,
        loading: false,
        pageFirst: action.payload,
      };

    case types.PUSH_LEAVES_SUCCESS:
      return {
        ...state,
        loading: false,
        leaves: action.payload,
      };
    case types.PUSH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        files: action.payload,
      };

    default:
      return state;
  }
};

export default stateReducer;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useExpandTableHooks(pageSize) {
  const dispatch = useDispatch();

  const { filters, statePage, pageFirst } = useSelector((state) => state.state);
  const [perPage, setPerPage] = useState(pageSize);
  const [page, setPage] = useState(statePage);
  const [first, setFirst] = useState(pageFirst);
  const [item, setItem] = useState({});
  const [expandedRows, setExpandedRows] = useState(null);
  const [rowClick, setRowClick] = useState(false);

  const handlePage = (event) => {
    setPage(event?.page + 1);
    setPerPage(event.rows);
    setFirst(event.first);
  };

  //cell class
  const CellClass = () => {
    return 'hover:text-indigo-900';
  };

  return {
    filters,
    statePage,
    perPage,
    setPerPage,
    page,
    setPage,
    first,
    setFirst,
    item,
    setItem,
    handlePage,
    CellClass,
    setExpandedRows,
    expandedRows,
    rowClick,
  };
}

export default useExpandTableHooks;

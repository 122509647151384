//title
export const LOAD_FIELDS_REQUEST = 'LOAD_FIELDS_REQUEST';
export const LOAD_FIELDS_SUCCESS = 'LOAD_FIELDS_SUCCESS';
export const LOAD_FIELDS_FAIL = 'LOAD_FIELDS_FAIL';

export const LOAD_ATTENDANCES_REQUEST = 'LOAD_ATTENDANCES_REQUEST';
export const LOAD_ATTENDANCES_SUCCESS = 'LOAD_ATTENDANCES_SUCCESS';
export const LOAD_ATTENDANCES_FAIL = 'LOAD_ATTENDANCES_FAIL';

export const LOAD_ATTENDANCE_REQUEST = 'LOAD_ATTENDANCE_REQUEST';
export const LOAD_ATTENDANCE_SUCCESS = 'LOAD_ATTENDANCE_SUCCESS';
export const LOAD_ATTENDANCE_FAIL = 'LOAD_ATTENDANCE_FAIL';

import { toast } from 'react-toastify';

toast.configure({
  position: 'top-right',
  autoClose: 3000,
  className: 'text-xs',
  toastClassName: 'rounded-md',
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

export default toast;

export const PUSH_FILTER_DATA_REQUEST = 'PUSH_FILTER_DATA_REQUEST';
export const PUSH_FILTER_DATA_SUCCESS = 'PUSH_FILTER_DATA_SUCCESS';

export const PUSH_PAGE_SIZE_SUCCESS = 'PUSH_PAGE_SIZE_SUCCESS';
export const PUSH_PAGE_SUCCESS = 'PUSH_PAGE_SUCCESS';
export const PUSH_PAGE_FIRST_SUCCESS = 'PUSH_PAGE_FIRST_SUCCESS';


export const PUSH_LEAVES_SUCCESS = 'PUSH_LEAVES_SUCCESS';
export const PUSH_DOCUMENTS_SUCCESS = 'PUSH_DOCUMENTS_SUCCESS';

import * as types from 'store/organization/types';

const initialState = {
  loading: false,
  paginationOption: {},
  errors: {},
  categorySection: [],
};

const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_CATEGORY_SECTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_CATEGORY_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        categorySection: action.payload.result,
      };

    case types.LOAD_CATEGORY_SECTION_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default organizationReducer;

import { Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import propType from 'prop-types';
function Accordion({ title, content, color, textColor }) {
  return (
    <div className='w-full px-4 mt-4'>
      <div className='mx-auto w-full rounded-2xl bg-white'>
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex text-sm font-bold w-full justify-between rounded-lg ${color} px-4 py-2 text-left ${textColor}`}
              >
                <span>{title}</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-black`}
                />
              </Disclosure.Button>
              <Transition
                show={open}
                enter='transition duration-500 ease-out'
                enterFrom='transform scale-95 opacity-0'
                enterTo='transform scale-100 opacity-100'
                leave='transition duration-500 ease-out'
                leaveFrom='transform scale-100 opacity-100'
                leaveTo='transform scale-95 opacity-0'
              >
                <Disclosure.Panel className='px-4 pt-2 pb-2 text-sm text-gray-500'>
                  {content}
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}

export default Accordion;

import * as types from 'store/state/types';

export const pushFilters = (data) => async (dispatch) => {
  await dispatch({ type: types.PUSH_FILTER_DATA_REQUEST });

  await dispatch({
    type: types.PUSH_FILTER_DATA_SUCCESS,
    payload: data,
  });
};

export const pushPage = (page) => async (dispatch) => {
  await dispatch({
    type: types.PUSH_PAGE_SUCCESS,
    payload: page,
  });
};

export const pushPageSize = (size) => async (dispatch) => {
  await dispatch({
    type: types.PUSH_PAGE_SIZE_SUCCESS,
    payload: size,
  });
};

export const pushPageFirst = (size) => async (dispatch) => {
  await dispatch({
    type: types.PUSH_PAGE_FIRST_SUCCESS,
    payload: size,
  });
};

export const pushLeaves = (data) => async (dispatch) => {
  await dispatch({
    type: types.PUSH_LEAVES_SUCCESS,
    payload: data,
  });
};

export const pushDocuments = (data) => async (dispatch) => {
  await dispatch({
    type: types.PUSH_DOCUMENTS_SUCCESS,
    payload: data,
  });
};

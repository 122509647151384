import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ToolTip } from 'components/shared';
import { GrFormView } from 'react-icons/gr';
import { TbEdit } from 'react-icons/tb';
import { MdArchive } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';

export default function ActionMenu({
  row,
  viewRoute,
  editRoute,
  archiveMenu,
  deleteMenu,
  setArchiveVisible,
  setDeleteVisible,
  setItem,
  viewModal,
  editModal,
  modalData,
}) {
  const navigation = useNavigate();
  return (
    <div className='flex items-center cursor-pointer'>
      <ToolTip
        title={
          viewRoute && (
            <GrFormView
              className='w-7 h-7 mr-1 text-[#251f47]'
              onClick={() => navigation(viewRoute.replace(':id', row?.id))}
            />
          )
        }
        type='light'
        content={
          <span className='text-[10px] text-sky-900 font-bold'>
            View Information
          </span>
        }
        id='single'
      />

      <ToolTip
        title={
          viewModal && (
            <GrFormView
              className='w-7 h-7 mr-1 text-[#251f47]'
              onClick={() => [viewModal(true), modalData(row)]}
            />
          )
        }
        type='light'
        content={
          <span className='text-[10px] text-sky-900 font-bold'>
            View Information
          </span>
        }
        id='modalsingle'
      />

      <ToolTip
        title={
          editRoute && (
            <TbEdit
              className='w-6 h-6 mr-1 text-[#ff7b00]'
              onClick={() => navigation(editRoute.replace(':id', row?.id))}
            />
          )
        }
        type='light'
        content={
          <span className='text-[10px] text-sky-900 font-bold'>
            Edit Information
          </span>
        }
        id='routeedit'
      />

      <ToolTip
        title={
          editModal && (
            <TbEdit
              className='w-6 h-6 mr-1 text-[#ff7b00]'
              onClick={() => [editModal(true), modalData(row)]}
            />
          )
        }
        type='light'
        content={
          <span className='text-[10px] text-sky-900 font-bold'>
            Edit Information
          </span>
        }
        id='modaledit'
      />

      <ToolTip
        title={
          archiveMenu && (
            <MdArchive
              className='w-6 h-6 mr-1 text-gray-600'
              onClick={() => [setArchiveVisible(true), setItem(row)]}
            />
          )
        }
        type='light'
        content={
          <span className='text-[10px] text-sky-900 font-bold'>
            Archive Information
          </span>
        }
        id='archive'
      />

      <ToolTip
        title={
          deleteMenu && (
            <RiDeleteBin6Line
              className='w-6 h-6 mr-1 text-red-900'
              onClick={() => [setDeleteVisible(true), setItem(row)]}
            />
          )
        }
        type='light'
        content={
          <span className='text-[10px] text-sky-900 font-bold'>
            Delete Information
          </span>
        }
        id='delete'
      />
    </div>
  );
}

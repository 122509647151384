import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { permission as usePermission } from 'store/auth/selector';
import Columns from './Menu';

function Index() {
  const permission = useSelector(usePermission);
  const navigation = useNavigate();
  const location = useLocation();
  const _ = require('lodash');
  const [open, setOpen] = useState(true);

  // const handleNavigation = (item) => {
  //   if (_.startsWith(item?.to, '/report')) {
  //     navigation(item?.to);
  //   } else {
  //     navigation(item?.to);
  //     navigation(0);
  //   }
  // };

  return (
    <React.Fragment>
      <div
        className={` ${
          open ? 'w-60  ' : ' w-30 '
        }   bg-gray-50 p-5 relative duration-300 md:block hidden`}
        style={{ height: 'fit-content' }}
      >
        <img
          src={require('assets/images/control.png')}
          className={`absolute cursor-pointer -right-3 top-4 w-7 border-dark-purple
           border-2 rounded-full  ${!open && 'rotate-180'}`}
          onClick={() => setOpen(!open)}
          alt='control'
        />

        {Columns.map((item, key) => {
          const isPermission = item?.policy
            ? permission?.some((v) => v?.description === item?.policy)
            : true;

          return item?.subMenu?.length ? (
            <details className='group mt-3  space-y-1 cursor-pointer' key={key}>
              <summary className='flex items-center py-3 px-3 font-medium rounded-lg hover:bg-[#cbf3f0]'>
                {item?.icon}

                <span className='ml-3  text-xs font-medium'>
                  {open && item?.name}{' '}
                </span>

                {open && (
                  <AiOutlineCaretDown className='ml-auto transition duration-300 shrink-0 group-open:-rotate-180' />
                )}
              </summary>

              {item?.subMenu?.map((value, index) => (
                <div key={index}>
                  <div
                    as='nav'
                    className='mt-3 ml-8 flex flex-col'
                    onClick={() => navigation(value?.to)}
                  >
                    {open && (
                      <div
                        hidden={!isPermission}
                        className={`flex items-center py-3 px-3 font-medium rounded-lg ${
                          location.pathname === value.to ? 'bg-[#cbf3f0]' : null
                        }`}
                      >
                        {value?.icon}
                        <span className='ml-3 text-xs'>
                          {open && value?.title}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </details>
          ) : (
            <div hidden={!isPermission} key={key}>
              <div
                as='nav'
                className='flex flex-col mt-4 cursor-pointer'
                onClick={() => navigation(item?.to)}
              >
                <div
                  className={`flex items-center py-4 px-3 font-medium rounded-lg ${
                    location.pathname === item?.to ? 'bg-[#cbf3f0]' : null
                  }`}
                >
                  {item?.icon}

                  <span className='ml-3 text-xs text-black tracking-wider'>
                    {open && item?.name}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}

export default Index;

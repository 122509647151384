import axios from 'config/axios';
import * as types from 'store/addon/types';

export const loadTitles = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_TITLE_REQUEST });
    const { data } = await axios.get('title');
    dispatch({
      type: types.LOAD_TITLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_TITLE_FAIL,
      payload: error,
    });
  }
};

export const loadEthnicity = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_ETHNICITY_REQUEST });
    const { data } = await axios.get('ethinicity');
    dispatch({
      type: types.LOAD_ETHNICITY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_ETHNICITY_FAIL,
      payload: error,
    });
  }
};

export const loadReligion = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_RELIGION_REQUEST });
    const { data } = await axios.get('religion');
    dispatch({
      type: types.LOAD_RELIGION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_RELIGION_FAIL,
      payload: error,
    });
  }
};

export const loadSubject = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_SUBJECT_REQUEST });
    const { data } = await axios.get('subject');
    dispatch({
      type: types.LOAD_SUBJECT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_SUBJECT_FAIL,
      payload: error,
    });
  }
};

export const loadMaritalStatus = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_MARITAL_STATUS_REQUEST });
    const { data } = await axios.get('maritial-status');
    dispatch({
      type: types.LOAD_MARITAL_STATUS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_MARITAL_STATUS_FAIL,
      payload: error,
    });
  }
};

export const loadPosition = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_POSITION_REQUEST });
    const { data } = await axios.get('position');
    dispatch({
      type: types.LOAD_POSITION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_POSITION_FAIL,
      payload: error,
    });
  }
};

//salary method
export const loadSalaryMethod = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_SALARY_METHOD_REQUEST });
    const { data } = await axios.get('salary-payment-type');
    dispatch({
      type: types.LOAD_SALARY_METHOD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_SALARY_METHOD_FAIL,
      payload: error,
    });
  }
};

//serving as

//grade
export const loadGrade = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_GRADE_REQUEST });
    const { data } = await axios.get('grade-class-service');
    dispatch({
      type: types.LOAD_GRADE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_GRADE_FAIL,
      payload: error,
    });
  }
};

//educational qualifications
export const loadHEQualifications = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_EDUCATIONAL_QUALIFICATIONS_REQUEST });
    const { data } = await axios.get('h-e-qualification');
    dispatch({
      type: types.LOAD_EDUCATIONAL_QUALIFICATIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_EDUCATIONAL_QUALIFICATIONS_FAIL,
      payload: error,
    });
  }
};

//professional qualifications
export const loadHPQualifications = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_PROFESSIONAL_QUALIFICATIONS_REQUEST });
    const { data } = await axios.get('prof-qualification');
    dispatch({
      type: types.LOAD_PROFESSIONAL_QUALIFICATIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_PROFESSIONAL_QUALIFICATIONS_FAIL,
      payload: error,
    });
  }
};

//classfications
export const loadClaffications = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_CLASSIFICATION_REQUEST });
    const { data } = await axios.get('clasification-of-appointment');
    dispatch({
      type: types.LOAD_CLASSIFICATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_CLASSIFICATION_FAIL,
      payload: error,
    });
  }
};

//language
export const loadLanguageMedium = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_CLASSIFICATION_REQUEST });
    const { data } = await axios.get('language-medium');
    dispatch({
      type: types.LOAD_LANGUAGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_LANGUAGE_FAIL,
      payload: error,
    });
  }
};

//work involved
export const loadWorkInvolved = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_WORKINVOLVED_REQUEST });
    const { data } = await axios.get('work-involved');
    dispatch({
      type: types.LOAD_WORKINVOLVED_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_WORKINVOLVED_FAIL,
      payload: error,
    });
  }
};

export const loadCategories = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_CATEGORIES_REQUEST });
    const { data } = await axios.get('get-category');
    dispatch({
      type: types.LOAD_CATEGORIES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_CATEGORIES_FAIL,
      payload: error,
    });
  }
};

export const loadArrivalDispatch = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_ARRIVAL_DISPATCH_REQUEST });
    const { data } = await axios.get('arrival-dispatch-template');
    dispatch({
      type: types.LOAD_ARRIVAL_DISPATCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_ARRIVAL_DISPATCH_FAIL,
      payload: error,
    });
  }
};

export const loadWorkingTimeTemplate = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_WORKING_TIME_REQUEST });
    const { data } = await axios.get('working-time-template');
    dispatch({
      type: types.LOAD_WORKING_TIME_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_WORKING_TIME_FAIL,
      payload: error,
    });
  }
};

export const loadStaffWorkingTimeTemplate = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_STAFF_WORKING_TIME_REQUEST });
    const { data } = await axios.get(`working-time-staff-id?id=${id}`);
    dispatch({
      type: types.LOAD_STAFF_WORKING_TIME_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_STAFF_WORKING_TIME_FAIL,
      payload: error,
    });
  }
};

export const loadStaffArrivalDispatch = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_STAFF_ARRIVAL_DISPATCH_REQUEST });
    const { data } = await axios.get(`arrival-dispatch-staff-id?id=${id}`);
    dispatch({
      type: types.LOAD_STAFF_ARRIVAL_DISPATCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_STAFF_ARRIVAL_DISPATCH_FAIL,
      payload: error,
    });
  }
};

export const storeWorkingTime =
  (staffId, workingId) => async (dispatch, getState) => {
    return await axios.post(
      `/allocate-working-time?staffId=${staffId}&workingId=${workingId}`
    );
  };

export const storeArrivalDispatch =
  (staffId, arrDisId) => async (dispatch, getState) => {
    return await axios.post(
      `allocate-arrival-dispatch?staffId=${staffId}&arrDisId=${arrDisId}`
    );
  };

export const loadWorkingTimes = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_WORKING_TIMES_REQUEST });
    const { data } = await axios.get(`get-working-time-template`);

    dispatch({
      type: types.LOAD_WORKING_TIMES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_WORKING_TIMES_FAIL,
      payload: error,
    });
  }
};

export const loadArrivalDepartures = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_ARRIVAL_DEPARTURES_REQUEST });
    const { data } = await axios.get(`get-arrival-dispatch-time-template`);
    dispatch({
      type: types.LOAD_ARRIVAL_DEPARTURES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_ARRIVAL_DEPARTURES_FAIL,
      payload: error,
    });
  }
};

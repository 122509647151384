import React from 'react';
import ReactTooltip from 'react-tooltip';

function ToolTip({ id, title, content, type }) {
  return (
    <>
      <div data-tip data-for={id}>
        {title}
      </div>

      <ReactTooltip id={id} type={type}>
        {content}
      </ReactTooltip>
    </>
  );
}

export default ToolTip;

import * as types from 'store/event/types';

const initialState = {
  loading: false,
  events: [],
  paginationOption: {},
  event: {},
  eventLoading: false,
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_EVENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        events: action.payload.results,
        paginationOption: {
          totalItems: action.payload?.totalItems,
          page: action.payload?.page,
          pageSize: action.payload?.pageSize,
          numberOfPages: action.payload?.numberOfPages,
          displayStart: action.payload?.displayStart,
          displayEnd: action.payload?.displayEnd,
          displayCount: action.payload?.displayCount,
        },
      };

    case types.LOAD_EVENTS_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    //edit
    case types.LOAD_EVENT_REQUEST:
      return {
        ...state,
        eventLoading: true,
      };

    case types.LOAD_EVENT_SUCCESS:
      return {
        ...state,
        eventLoading: false,
        event: action.payload.result,
      };

    case types.LOAD_EVENT_FAIL:
      return {
        ...state,
        eventLoading: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default eventReducer;

import * as types from 'store/attendance/types';

const initialState = {
  loading: false,
  fields: [],
  attendances: [],
  attendance: [],
  loadingAttendance: false,
};

const attendanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_FIELDS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_FIELDS_SUCCESS:
      return {
        ...state,
        loading: false,
        fields: action.payload.result,
      };

    case types.LOAD_FIELDS_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case types.LOAD_ATTENDANCES_REQUEST:
      return {
        ...state,
        loadingAttendance: true,
      };

    case types.LOAD_ATTENDANCES_SUCCESS:
      return {
        ...state,
        loadingAttendance: false,
        attendances: action.payload.results,
        paginationOption: {
          totalItems: action.payload?.totalItems,
          page: action.payload?.page,
          pageSize: action.payload?.pageSize,
          numberOfPages: action.payload?.numberOfPages,
          displayStart: action.payload?.displayStart,
          displayEnd: action.payload?.displayEnd,
          displayCount: action.payload?.displayCount,
        },
      };

    case types.LOAD_ATTENDANCES_FAIL:
      return {
        ...state,
        loadingAttendance: false,
        errors: action.payload,
      };
    case types.LOAD_ATTENDANCE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_ATTENDANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        attendance: action.payload.result,
      };

    case types.LOAD_ATTENDANCE_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default attendanceReducer;

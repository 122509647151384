import React from 'react';
import propType from 'prop-types';
function Search({ placeholder, onSearch, value }) {
  return (
    <div>
      <input
        onChange={onSearch}
        value={value}
        type='text'
        placeholder={placeholder}
        className='block p-2 md:w-60 w-full text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-gray-500 focus:border-gray-500 '
      />
    </div>
  );
}

Search.propType = {
  placeholder: propType.string,
  onSearch: propType.bool,
};
export default Search;

import * as React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

export default function ArchiveModal({
  visible,
  setVisible,
  item,
  onArchiveItem,
}) {
  const modalFooter = (
    <React.Fragment>
      <Button
        label='No'
        icon='pi pi-times'
        className='bg-white border-none text-gray-900'
        onClick={() => setVisible(false)}
      />
      <Button
        label='Yes'
        onClick={() => [onArchiveItem(item), setVisible(false)]}
        icon='pi pi-check'
        className=' text-white bg-gray-800 border-none'
      />
    </React.Fragment>
  );

  const hideDeleteProductDialog = () => {
    setVisible(false);
  };

  return (
    <React.Fragment>
      <Dialog
        visible={visible}
        style={{ width: '450px' }}
        header='Confirm'
        modal
        footer={modalFooter}
        onHide={hideDeleteProductDialog}
      >
        <div className='flex items-center'>
          <i
            className='pi pi-exclamation-triangle mr-3'
            style={{ fontSize: '2rem' }}
          />
          <span>Do you want to archive?</span>
        </div>
      </Dialog>
    </React.Fragment>
  );
}

import * as types from 'store/dashboard/types';

const initialState = {
  currentLeave: [],
  staffCount: '',
  leaveCount: '',
  eventCount: '',
  attendanceCount: '',
  arrivalCount: [],
  dashboardCountLoading: false,
  loading: false,
  dashboardEvents: [],
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_EVENT_CALENDER_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_EVENT_CALENDER_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardEvents: action.payload.result,
      };

    case types.LOAD_EVENT_CALENDER_VIEW_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case types.LOAD_STAFF_COUNT_REQUEST:
      return {
        ...state,
        dashboardCountLoading: true,
      };

    case types.LOAD_STAFF_COUNT_SUCCESS:
      return {
        ...state,
        dashboardCountLoading: false,
        staffCount: action.payload,
      };

    case types.LOAD_STAFF_COUNT_FAIL:
      return {
        ...state,
        dashboardCountLoading: false,
        errors: action.payload,
      };

    //LEAVE
    case types.LOAD_LEAVE_COUNT_REQUEST:
      return {
        ...state,
        dashboardCountLoading: true,
      };

    case types.LOAD_LEAVE_COUNT_SUCCESS:
      return {
        ...state,
        dashboardCountLoading: false,
        leaveCount: action.payload,
      };

    case types.LOAD_LEAVE_COUNT_FAIL:
      return {
        ...state,
        dashboardCountLoading: false,
        errors: action.payload,
      };

    //ATTENDANCE
    case types.LOAD_ATTENDANCE_COUNT_REQUEST:
      return {
        ...state,
        dashboardCountLoading: true,
      };

    case types.LOAD_ATTENDANCE_COUNT_SUCCESS:
      return {
        ...state,
        dashboardCountLoading: false,
        attendanceCount: action.payload,
      };

    case types.LOAD_ATTENDANCE_COUNT_FAIL:
      return {
        ...state,
        dashboardCountLoading: false,
        errors: action.payload,
      };

    //EVENT
    case types.LOAD_EVENT_COUNT_REQUEST:
      return {
        ...state,
        dashboardCountLoading: true,
      };

    case types.LOAD_EVENT_COUNT_SUCCESS:
      return {
        ...state,
        dashboardCountLoading: false,
        eventCount: action.payload,
      };

    case types.LOAD_EVENT_COUNT_FAIL:
      return {
        ...state,
        dashboardCountLoading: false,
        errors: action.payload,
      };
    //AD
    case types.LOAD_AD_COUNT_REQUEST:
      return {
        ...state,
        dashboardCountLoading: true,
      };

    case types.LOAD_AD_COUNT_SUCCESS:
      return {
        ...state,
        dashboardCountLoading: false,
        arrivalCount: action.payload,
        // departureCount: action.payload[1].values,
      };

    case types.LOAD_AD_COUNT_FAIL:
      return {
        ...state,
        dashboardCountLoading: false,
        errors: action.payload,
      };

    case types.LOAD_CURENT_LEAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_CURENT_LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        currentLeave: action.payload.result,
      };

    case types.LOAD_CURENT_LEAVE_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default dashboardReducer;

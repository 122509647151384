//title
export const LOAD_LEAVES_REQUEST = 'LOAD_LEAVES_REQUEST';
export const LOAD_LEAVES_SUCCESS = 'LOAD_LEAVES_SUCCESS';
export const LOAD_LEAVES_FAIL = 'LOAD_LEAVES_FAIL';

export const LOAD_LEAVE_REQUEST = 'LOAD_LEAVE_REQUEST';
export const LOAD_LEAVE_SUCCESS = 'LOAD_LEAVE_SUCCESS';
export const LOAD_LEAVE_FAIL = 'LOAD_LEAVE_FAIL';

export const LOAD_STAFF_LEAVE_REQUEST = 'LOAD_STAFF_LEAVE_REQUEST';
export const LOAD_STAFF_LEAVE_SUCCESS = 'LOAD_STAFF_LEAVE_SUCCESS';
export const LOAD_STAFF_LEAVE_FAIL = 'LOAD_STAFF_LEAVE_FAIL';

export const LOAD_LEAVE_STATUS_REQUEST = 'LOAD_LEAVE_STATUS_REQUEST';
export const LOAD_LEAVE_STATUS_SUCCESS = 'LOAD_LEAVE_STATUS_SUCCESS';
export const LOAD_LEAVE_STATUS_FAIL = 'LOAD_LEAVE_STATUS_FAIL';

export const LOAD_LEAVE_TYPE_REQUEST = 'LOAD_LEAVE_TYPE_REQUEST';
export const LOAD_LEAVE_TYPE_SUCCESS = 'LOAD_LEAVE_TYPE_SUCCESS';
export const LOAD_LEAVE_TYPE_FAIL = 'LOAD_LEAVE_TYPE_FAIL';

import * as React from 'react';
import Lottie from 'lottie-react';
import Nodata from 'assets/animations/no-data.json';

export default function NoData() {
  return (
    <React.Fragment>
      <div className='flex justify-center	justify-items-center py-48	'>
        <Lottie
          className='w-32 h-1/12'
          animationData={Nodata}
        />
      </div>
    </React.Fragment>
  );
}

import * as routes from 'constants/Routes';

const routeItems = [
  {
    path: routes.HOME,
    component: 'Dashboard',
  },
  {
    policy: 'masterdata.index',
    path: routes.MASTER_DATA,
    component: 'Settings',
  },
  {
    policy: 'attendance.index',
    path: routes.ATTENDANCES,
    component: 'Attendance',
  },

  {
    policy: 'staff.index',
    path: routes.STAFF,
    component: 'Staff',
  },
  {
    policy: 'staff.create',
    path: routes.STAFF_CREATE,
    component: 'Staff/Create',
  },
  {
    policy: 'staff.edit',
    path: routes.STAFF_EDIT,
    component: 'Staff/Edit',
  },
  {
    policy: 'staff.view',
    path: routes.STAFF_VIEW,
    component: 'Staff/View',
  },
  {
    policy: 'event.index',
    path: routes.EVENTS,
    component: 'Events',
  },
  {
    policy: 'event.create',
    path: routes.EVENTS_CREATE,
    component: 'Events/Create',
  },
  {
    policy: 'event.edit',
    path: routes.EVENTS_EDIT,
    component: 'Events/Edit',
  },
  {
    policy: 'organization.index',
    path: routes.ORGANIZATION,
    component: 'Organization',
  },
  {
    policy: 'holiday.index',
    path: routes.HOLIDAYS,
    component: 'Holidays',
  },
  {
    policy: 'leave.index',
    path: routes.LEAVE,
    component: 'Leaves',
  },
  {
    policy: 'leave.create',
    path: routes.LEAVE_CREATE,
    component: 'Leaves/Create',
  },
  {
    policy: 'leave.edit',
    path: routes.LEAVE_EDIT,
    component: 'Leaves/Edit',
  },
  {
    policy: 'leave.view',
    path: routes.LEAVE_VIEW,
    component: 'Leaves/View',
  },

  {
    policy: 'user.index',
    path: routes.USER,
    component: 'Users',
  },

  {
    policy: 'role.index',
    path: routes.ROLES,
    component: 'Roles',
  },

  {
    policy: 'sms.index',
    path: routes.SMS,
    component: 'SMS',
  },

  {
    policy: 'sms.template',
    path: routes.SMS_TEMPLATE,
    component: 'SMS/Template',
  },

  {
    policy: 'report.view',
    path: routes.LEAVE_REPORT,
    component: 'Reports/Leave/View',
  },

  {
    policy: 'reports.leave',
    path: routes.LEAVE_REPORTS,
    component: 'Reports/Leave',
  },

  {
    policy: 'reports.leave-summary',
    path: routes.LEAVE_SUMMARY_REPORTS,
    component: 'Reports/LeaveSummary',
  },

  {
    policy: 'reports.staff',
    path: routes.STAFF_REPORTS,
    component: 'Reports/Staff',
  },
  {
    policy: 'reports.working-time',
    path: routes.WORKING_TIME_REPORTS,
    component: 'Reports/WorkingTime',
  },
  {
    policy: 'reports.attendance',
    path: routes.ATTENDANCE_REPORTS,
    component: 'Reports/Attendance',
  },
];

export default routeItems;

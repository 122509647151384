import axios from 'config/axios';
import { authURL } from 'config/env';
import * as types from 'store/auth/types';

export const login = (values) => {
  return async (dispatch) => {
    const responce = await axios.post(authURL, values);

    const loginSuccessAction = {
      type: types.LOGIN_SUCCESS,
      payload: responce.data,
    };
    dispatch(loginSuccessAction);
    return responce;
  };
};

export const logout = () => {
  const logoutAction = {
    type: types.PURGE,
    result: () => null,
  };

  return logoutAction;
};

export const storeAvatar = (id, values) => async (dispatch, getState) => {
  return await axios.post(`/set-avatar?id=${id}`, values);
};

export const storeDocuments = (id, values) => async (dispatch, getState) => {
  return await axios.post(`/set-document?id=${id}`, values, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

import React, { useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { Button, Breadcrumb } from 'components/shared';
import Select from 'components/shared/Select/SelectNV';
import { Calendar } from 'primereact/calendar';
import { Search } from 'components/shared';
import { pushFilters } from 'store/state/actions';
import { loadCategories } from 'store/addon/actions';
import { loadTypes } from 'store/leave/actions';

function Filteration({ createRoute, createModal, breadCrumbRoutes }) {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(loadCategories());
    dispatch(loadTypes());
  }, [dispatch]);

  const { categories } = useSelector((state) => state.addon);
  const { filters } = useSelector((state) => state.state);
  const { leaveTypes } = useSelector((state) => state.leave);

  const [section, setSection] = useState('');
  const [leaveType, setLeaveType] = useState('');
  const [date, setDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);

  const filter = filters ? Object.keys(filters) : [];

  const visible = (value) => {
    return filter?.find((e) => e === value) ? false : true;
  };

  const pushFilter = (filter) => {
    dispatch(pushFilters(filter));
  };

  const status = [
    { name: 'In', id: 'In' },
    { name: 'Out', id: 'Out' },
  ];

  return (
    <div className='flex md:flex-row flex-col md:justify-between md:items-center md:px-4 px-1'>
      <div className='md:block hidden'>
        <Breadcrumb routes={breadCrumbRoutes} />
      </div>

      <div className='flex md:flex-row flex-col md:items-center'>
        <div hidden={visible('singleDate')}>
          <Calendar
            value={date}
            onChange={(e) => {
              filters.startDate = moment(e.value).format('YYYY/MM/DD');
              pushFilter(filters);
              setDate(e?.value);
            }}
            dateFormat='dd-mm-yy'
            className='h-9 md:w-64 w-full text-xs p-calendar mt-[0.5px] md:mr-3'
            placeholder='Select Date'
            showButtonBar
          />
        </div>

        <div
          className='h-9 md:w-48 w-full md:mt-0 mt-3'
          hidden={visible('category')}
        >
          <Select
            name='Select category'
            placeholder='Category'
            options={categories}
            onChange={(item) => {
              setSection([]);
              setIsLoading(true);
              setSection(item?.section);
              filters.category = item;
              filters.section = null;
              pushFilter(filters);
              setTimeout(() => {
                setIsLoading(false);
              }, 50);
            }}
            getOptionLabel='name'
            getOptionValue='id'
            values={filters.category}
            errors=''
            touches=''
          />
        </div>

        {isLoading === false && (
          <div
            className='h-9 md:w-48 w-full md:mt-0 mt-3 md:mx-4'
            hidden={visible('section')}
          >
            <Select
              name='Select section'
              placeholder='Section'
              options={section}
              disabled={section?.length === 0}
              onChange={(item) => {
                filters.section = item;
                pushFilter(filters);
              }}
              getOptionLabel='name'
              getOptionValue='id'
              values={filters.section}
            />
          </div>
        )}
        <div
          className='h-9 md:w-48 w-full md:mt-0 mt-3 md:mx-3'
          hidden={visible('inout')}
        >
          <Select
            name='Select Status'
            placeholder='Status'
            options={status}
            onChange={(item) => {
              filters.inout = item;
              pushFilter(filters);
            }}
            getOptionLabel='name'
            getOptionValue='id'
            values={filters.inout}
          />
        </div>

        <div
          className='h-9 md:w-48 w-full md:mt-0 mt-3 md:mx-3'
          hidden={visible('leaveType')}
        >
          <Select
            name='Select LeaveType'
            placeholder='LeaveType'
            options={leaveTypes}
            onChange={(item) => {
              filters.leaveType = item;
              pushFilter(filters);
            }}
            getOptionLabel='description'
            getOptionValue='id'
            values={filters.leaveType}
          />
        </div>

        <div
          className='md:w-[15vw] md:mx-3 md:mt-0 mt-3 w-full'
          hidden={visible('range')}
        >
          <DatePicker
            selectsRange={true}
            startDate={dateRange[0]}
            endDate={dateRange[1]}
            placeholderText='Date Range'
            onChange={(update) => {
              setDateRange(update);
              filters.startDate = update[0]
                ? moment(update[0]).format('YYYY-MM-DD')
                : null;
              filters.endDate = update[1]
                ? moment(update[1]).format('YYYY-MM-DD')
                : null;
              pushFilter(filters);
            }}
            className='block p-2 w-full px-3 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs'
            isClearable={true}
            popperClassName='z-40'
            dateFormat='dd/MM/yyyy'
          />
        </div>

        <div className='md:mt-0 mt-3 md:mr-2' hidden={visible('query')}>
          <Search
            placeholder='search'
            onSearch={(value) => {
              filters.query = value?.target?.value;
              pushFilter(filters);
            }}
            value={filters?.query}
          />
        </div>

        {createRoute && (
          <Link to={createRoute} className='py-1 md:mt-0 mt-3'>
            <Button
              name='Create'
              className='bg-[#fae0e4] md:w-32 w-full text-[#af211f] font-bold text-xs py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            />
          </Link>
        )}
        {createModal && (
          <div className='md:py-1 md:mt-0 mt-3'>
            <Button
              name='Create'
              onClick={() => createModal(true)}
              className='bg-[#fae0e4] md:w-32 w-full text-[#af211f] font-bold text-xs py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Filteration;

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useStore } from 'store';
import { ErrorBoundary, Loading } from 'components/base';
import { name, lang } from 'config/env';

function AppProvider({ children }) {
  const { store, persist } = useStore();

  return (
    <ErrorBoundary>
      {store && persist ? (
        <Provider store={store}>
          <PersistGate loading={<Loading />} persistor={persist}>
            <HelmetProvider>
              <Helmet>
                <html lang={lang} />
                <title>{name}</title>
              </Helmet>
              <BrowserRouter>{children}</BrowserRouter>
            </HelmetProvider>
          </PersistGate>
        </Provider>
      ) : (
        <Loading />
      )}
    </ErrorBoundary>
  );
}

export default AppProvider;

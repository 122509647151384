import React from 'react';
import { Switch as SwitchComponent } from '@headlessui/react';

function Switch({ enabled, onChange, disabled }) {
  return (
    <SwitchComponent
      checked={enabled}
      onChange={onChange}
      disabled={disabled}
      className='bg-[#fae0e4] relative inline-flex h-6 w-11 items-center rounded-full'
    >
      <span
        className={`${
          enabled ? 'translate-x-6' : 'translate-x-1'
        } inline-block h-4 w-4 transform rounded-full bg-[#af211f] transition`}
      />
    </SwitchComponent>
  );
}

export default Switch;

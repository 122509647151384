import React, { useMemo } from 'react';
import PhoneNumberInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
function PhoneInput({
  name,
  label,
  placeholder,
  className,
  onChange,
  values,
  errors,
  touches,
  onBlur,
  onFocus,
  disabled,
  disableDropdown,
}) {
  const isInvalid = useMemo(() => {
    return touches[name] && errors[name];
  }, [touches, errors, name]);
  return (
    <div>
      <label className='block text-[11px] mb-2 font-bold text-sky-900'>
        {label}
      </label>
      <PhoneNumberInput
        inputStyle={{ width: '100%', borderColor: '#f9f8fc' }}
        specialLabel={false}
        value={values[name]}
        placeholder={placeholder}
        country={'lk'}
        inputClass={
          className
            ? className
            : ` flex items-center w-full px-2 h-11 py-3 mt-3 text-xs border-gray-500 rounded-md`
        }
        onChange={onChange}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        disableDropdown={disableDropdown}
      />
      {isInvalid && (
        <p className='text-red-500 font-[600] text-[10px] mt-1'>
          {errors[name]}
        </p>
      )}
    </div>
  );
}

export default PhoneInput;

import * as types from 'store/staff/types';

const initialState = {
  loading: false,
  staffs: [],
  paginationOption: {},
  staff: {},
  staffDocuments: [],
  allStaff: [],
  staffsByCategory: [],
  staffsByCategoryLoading: false,
};

const staffReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_STAFFS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_STAFFS_SUCCESS:
      return {
        ...state,
        loading: false,
        staffs: action.payload.results,
        paginationOption: {
          totalItems: action.payload?.totalItems,
          page: action.payload?.page,
          pageSize: action.payload?.pageSize,
          numberOfPages: action.payload?.numberOfPages,
          displayStart: action.payload?.displayStart,
          displayEnd: action.payload?.displayEnd,
          displayCount: action.payload?.displayCount,
        },
      };

    case types.LOAD_STAFFS_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case types.LOAD_STAFF_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_STAFF_SUCCESS:
      return {
        ...state,
        loading: false,
        staff: action.payload,
      };

    case types.LOAD_STAFF_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case types.LOAD_STAFF_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_STAFF_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        staffDocuments: action?.payload?.result?.fileDetails,
      };

    case types.LOAD_STAFF_DOCUMENT_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case types.DELETE_STAFF_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_STAFF_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.DELETE_STAFF_DOCUMENT_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    //dsfsfs

    case types.LOAD_ALL_STAFFS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_ALL_STAFFS_SUCCESS:
      return {
        ...state,
        loading: false,
        allStaff: action.payload.result,
      };

    case types.LOAD_ALL_STAFFS_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    //
    case types.LOAD_STAFFS_BY_CATEGORY_REQUEST:
      return {
        ...state,
        staffsByCategoryLoading: true,
      };

    case types.LOAD_STAFFS_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        staffsByCategoryLoading: false,
        staffsByCategory: action.payload.result,
      };

    case types.LOAD_STAFFS_BY_CATEGORY_FAIL:
      return {
        ...state,
        staffsByCategoryLoading: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default staffReducer;

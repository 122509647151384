export const LOAD_ALL_ROLES_WITH_PERMISSION_REQUEST =
  'LOAD_ALL_ROLES_WITH_PERMISSION_REQUEST';
export const LOAD_ALL_ROLES_WITH_PERMISSION_SUCCESS =
  'LOAD_ALL_ROLES_WITH_PERMISSION_SUCCESS';
export const LOAD_ALL_ROLES_WITH_PERMISSION_FAIL =
  'LOAD_ALL_ROLES_WITH_PERMISSION_FAIL';

export const LOAD_ROLES_REQUEST = 'LOAD_ROLES_REQUEST';
export const LOAD_ROLES_SUCCESS = 'LOAD_ROLES_SUCCESS';
export const LOAD_ROLES_FAIL = 'LOAD_ROLES_FAIL';

import * as types from 'store/report/types';

const initialState = {
  paginationOption: {},
  loading: false,
  leaves: [],
  leave: {},
  leaveDownloadReport: [],
  leaveLoading: false,

  attendances: [],
  attendanceLoading: false,

  workingTimeReport: [],
  workingTimeLoading: false,

  leaveSummaryReport: [],
  leaveMonthlySummaryReport: [],

  leaveSummaryLoading: false,
  leaveMonthlyReportLoading: false,

  staffReport: [],
  staffLoading: false,
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    //leavereport//
    case types.LOAD_LEAVE_REPORTS_REQUEST:
      return {
        ...state,
        leaveLoading: true,
      };

    case types.LOAD_LEAVE_REPORTS_SUCCESS:
      return {
        ...state,
        leaveLoading: false,
        leaves: action.payload.results,
        paginationOption: {
          totalItems: action.payload?.totalItems,
          page: action.payload?.page,
          pageSize: action.payload?.pageSize,
          numberOfPages: action.payload?.numberOfPages,
          displayStart: action.payload?.displayStart,
          displayEnd: action.payload?.displayEnd,
          displayCount: action.payload?.displayCount,
        },
      };

    case types.LOAD_LEAVE_REPORTS_FAIL:
      return {
        ...state,
        leaveLoading: false,
        errors: action.payload,
      };

    //single leavereport//
    case types.LOAD_LEAVE_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_LEAVE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        leave: action.payload,
      };

    case types.LOAD_LEAVE_REPORT_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    //leavedownloadreport
    case types.LOAD_LEAVE_DOWNLOAD_REPORT_REQUEST:
      return {
        ...state,
      };

    case types.LOAD_LEAVE_DOWNLOAD_REPORT_SUCCESS:
      return {
        ...state,
        leaveDownloadReport: action.payload,
      };

    case types.LOAD_LEAVE_DOWNLOAD_REPORT_FAIL:
      return {
        ...state,
        errors: action.payload,
      };

    //attendanceReport
    case types.LOAD_ATTENDANCE_REPORTS_REQUEST:
      return {
        ...state,
        attendanceLoading: true,
      };

    case types.LOAD_ATTENDANCE_REPORTS_SUCCESS:
      return {
        ...state,
        attendanceLoading: false,
        attendances: action.payload.results,
        paginationOption: {
          totalItems: action.payload?.totalItems,
          page: action.payload?.page,
          pageSize: action.payload?.pageSize,
          numberOfPages: action.payload?.numberOfPages,
          displayStart: action.payload?.displayStart,
          displayEnd: action.payload?.displayEnd,
          displayCount: action.payload?.displayCount,
        },
      };

    case types.LOAD_ATTENDANCE_REPORTS_FAIL:
      return {
        ...state,
        attendanceLoading: false,
        errors: action.payload,
      };

    //workingtimereport
    case types.LOAD_WORKING_TIME_REPORT_REQUEST:
      return {
        ...state,
        workingTimeLoading: true,
      };

    case types.LOAD_WORKING_TIME_REPORT_SUCCESS:
      return {
        ...state,
        workingTimeLoading: false,
        workingTimeReport: action.payload.results,
        paginationOption: {
          totalItems: action.payload?.totalItems,
          page: action.payload?.page,
          pageSize: action.payload?.pageSize,
          numberOfPages: action.payload?.numberOfPages,
          displayStart: action.payload?.displayStart,
          displayEnd: action.payload?.displayEnd,
          displayCount: action.payload?.displayCount,
        },
      };

    case types.LOAD_WORKING_TIME_REPORT_FAIL:
      return {
        ...state,
        workingTimeLoading: false,
        errors: action.payload,
      };

    //leave summary
    case types.LOAD_LEAVE_SUMMARY_REPORT_REQUEST:
      return {
        ...state,
        leaveSummaryLoading: true,
      };

    case types.LOAD_LEAVE_SUMMARY_REPORT_SUCCESS:
      return {
        ...state,
        leaveSummaryLoading: false,
        leaveSummaryReport: action.payload.results,
        paginationOption: {
          totalItems: action.payload?.totalItems,
          page: action.payload?.page,
          pageSize: action.payload?.pageSize,
          numberOfPages: action.payload?.numberOfPages,
          displayStart: action.payload?.displayStart,
          displayEnd: action.payload?.displayEnd,
          displayCount: action.payload?.displayCount,
        },
      };

    case types.LOAD_LEAVE_SUMMARY_REPORT_FAIL:
      return {
        ...state,
        leaveSummaryLoading: false,
        errors: action.payload,
      };

    //staff report
    case types.LOAD_STAFF_REPORT_REQUEST:
      return {
        ...state,
        staffLoading: true,
      };

    case types.LOAD_STAFF_REPORT_SUCCESS:
      return {
        ...state,
        staffLoading: false,
        staffReport: action.payload.results,
        paginationOption: {
          totalItems: action.payload?.totalItems,
          page: action.payload?.page,
          pageSize: action.payload?.pageSize,
          numberOfPages: action.payload?.numberOfPages,
          displayStart: action.payload?.displayStart,
          displayEnd: action.payload?.displayEnd,
          displayCount: action.payload?.displayCount,
        },
      };

    case types.LOAD_STAFF_REPORT_FAIL:
      return {
        ...state,
        staffLoading: false,
        errors: action.payload,
      };

    //staff month leave summary

    case types.LOAD_STAFF_MONTHLY_LEAVE_REPORT_REQUEST:
      return {
        ...state,
        leaveMonthlyReportLoading: true,
      };

    case types.LOAD_STAFF_MONTHLY_LEAVE_REPORT_SUCCESS:
      return {
        ...state,
        leaveMonthlyReportLoading: false,
        leaveMonthlySummaryReport: action.payload.result,
      };

    case types.LOAD_STAFF_MONTHLY_LEAVE_REPORT_FAIL:
      return {
        ...state,
        leaveMonthlyReportLoading: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default reportReducer;

import React, { useState, useMemo, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pushPage, pushPageFirst, pushPageSize } from 'store/state/actions';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import ActionMenu from './ActionMenu';
import NoData from './NoData';
import DeleteModal from './DeleteModal';
import ArchiveModal from './ArchiveModal';
import toast from 'lib/toast';
import { Filteration } from 'components/base';

export default function ResourceTable({
  columns,
  data,
  loading,
  loadData,
  pagination,
  pageSize,
  createRoute,
  viewRoute,
  editRoute,
  archiveMenu,
  deleteMenu,
  tableMenu,
  actionMenu,
  deleteAction,
  archiveAction,
  createModal,
  viewModal,
  editModal,
  modalData,
  breadCrumbRoutes,
  selection,
  onSelectionChange,
  selectionMode,
  deleteActionMessage,
  archiveActionMessage,
}) {
  //dispatch and navigation hooks
  const dispatch = useDispatch();

  //filter from state
  const { filters, statePage, pageFirst } = useSelector((state) => state.state);

  //use state hooks
  const [archiveVisible, setArchiveVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [perPage, setPerPage] = useState(pageSize);
  const [page, setPage] = useState(statePage);
  const [first, setFirst] = useState(pageFirst);
  const [item, setItem] = useState({});

  const actionTemplate = (row) => {
    return (
      <React.Fragment>
        <ActionMenu
          row={row}
          setArchiveVisible={setArchiveVisible}
          setDeleteVisible={setDeleteVisible}
          setItem={setItem}
          viewRoute={viewRoute}
          editRoute={editRoute}
          archiveMenu={archiveMenu}
          deleteMenu={deleteMenu}
          viewModal={viewModal}
          editModal={editModal}
          modalData={modalData}
        />
      </React.Fragment>
    );
  };

  //columns
  const tableColumns = columns?.map((col, i) => {
    return (
      <Column
        key={i}
        field={col.field}
        header={col.header}
        body={col.body}
        style={col.style}
        selectionMode={col.selection}
        headerStyle={col.headerStyle}
        align={col.align ? col.align : 'left'}
        alignHeader={col.headerAlign ? col.headerAlign : 'left'}
      />
    );
  });

  //with action menu
  const modifiedColumns = useMemo(() => {
    return [...tableColumns, <Column header='Actions' body={actionTemplate} />];
  }, []);

  const resourceColumns = actionMenu ? modifiedColumns : tableColumns;

  //pagination hooks

  const handlePage = (event) => {
    setPage(event?.page + 1);
    setPerPage(event.rows);
    setFirst(event.first);
    dispatch(pushPage(event?.page + 1));
    dispatch(pushPageSize(event.rows));
    dispatch(pushPageFirst(event.first));
  };

  //cell class
  const CellClass = () => {
    return 'hover:text-indigo-900';
  };

  //use effect for dispatch actions
  useLayoutEffect(() => {
    if (loadData) {
      dispatch(loadData(page, perPage, filters));
      dispatch(pushPage(page));
      dispatch(pushPageSize(perPage));
      dispatch(pushPageFirst(first));
    }
  }, [
    dispatch,
    perPage,
    page,
    first,
    filters?.startDate,
    filters?.query,
    filters?.category?.id,
    filters?.section?.id,
    filters?.endDate,
    filters?.leaveType?.id,
    filters?.staff?.id,
  ]);

  const onDeleteItem = React.useCallback(async (item) => {
    await dispatch(deleteAction(item?.id));
    await dispatch(loadData(page, perPage, filters));
    toast.success(
      `${
        deleteActionMessage
          ? deleteActionMessage
          : item?.title
          ? item?.title
          : item?.firstName
          ? item?.firstName
          : item?.name
      } Deleted Successfully`
    );
  }, []);

  const onArchiveItem = React.useCallback(async (item) => {
    await dispatch(archiveAction(item?.id));
    toast.success(
      `${
        archiveActionMessage
          ? archiveActionMessage
          : item?.firstname
          ? item?.firstname
          : item?.title
      }${
        item?.isActive ? ' Archived Successfully' : ' UnArchived Successfully'
      }`
    );
    await dispatch(loadData(page, perPage, filters));
  }, []);

  return (
    <React.Fragment>
      <div>
        {tableMenu && (
          <Filteration
            createRoute={createRoute}
            createModal={createModal}
            breadCrumbRoutes={breadCrumbRoutes}
          />
        )}
      </div>
      <div className='overflow-hidden shadow-sm'>
        <DataTable
          value={data}
          dataKey='id'
          responsiveLayout='stack'
          breakpoint='960px'
          emptyMessage={NoData}
          className='text-[12px] tracking-widest font-medium'
          cellClassName={CellClass}
          rowHover
          selection={selection}
          loading={loading}
          onSelectionChange={onSelectionChange}
          selectionMode={selectionMode}
          lazy
        >
          {resourceColumns}
        </DataTable>
        {pagination && (
          <div className='flex md:justify-end justify-center'>
            <Paginator
              rows={perPage}
              totalRecords={pagination?.totalItems}
              rowsPerPageOptions={[10, 20, 50, 100, 150, 200]}
              onPageChange={handlePage}
              first={first}
              className='w-full justify-end font-display'
            />
          </div>
        )}
      </div>

      <DeleteModal
        visible={deleteVisible}
        setVisible={setDeleteVisible}
        item={item}
        onDeleteItem={onDeleteItem}
      />
      <ArchiveModal
        visible={archiveVisible}
        setVisible={setArchiveVisible}
        item={item}
        onArchiveItem={onArchiveItem}
      />
    </React.Fragment>
  );
}

import React from 'react';
import { Dialog } from 'primereact/dialog';
function Modal({ isOpen, setISOpen, title, content }) {
  return (
    <div>
      <Dialog
        visible={isOpen}
        onHide={() => setISOpen(false)}
        breakpoints={{ '960px': '75vw', '640px': '100vw' }}
        header={title}
        headerClassName='text-indigo-600'
      >
        {content}
      </Dialog>
    </div>
  );
}

export default Modal;

import axios from 'config/axios';
import * as types from 'store/dashboard/types';

export const loadDashboardEvents = (year, month) => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_EVENT_CALENDER_VIEW_REQUEST });

    const { data } = await axios.get(
      `/events-calendar-view${month ? '?month=' + month : ''}${
        year ? '&year=' + year : ''
      }`
    );

    dispatch({
      type: types.LOAD_EVENT_CALENDER_VIEW_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_EVENT_CALENDER_VIEW_FAIL,
      payload: error,
    });
  }
};

//staff
export const countStaffDetails = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_STAFF_COUNT_REQUEST });

    const { data } = await axios.get('/staff-count');

    dispatch({
      type: types.LOAD_STAFF_COUNT_SUCCESS,
      payload: data.result,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_STAFF_COUNT_FAIL,
      payload: error,
    });
  }
};

//leave
export const countLeaveDetails = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_LEAVE_COUNT_REQUEST });

    const { data } = await axios.get('/leave-count');

    dispatch({
      type: types.LOAD_LEAVE_COUNT_SUCCESS,
      payload: data.result,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_LEAVE_COUNT_FAIL,
      payload: error,
    });
  }
};

//event
export const countEventDetails = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_EVENT_COUNT_REQUEST });

    const { data } = await axios.get('/event-count');

    dispatch({
      type: types.LOAD_EVENT_COUNT_SUCCESS,
      payload: data.result,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_EVENT_COUNT_FAIL,
      payload: error,
    });
  }
};

//ATTENDANCE
export const countAttendanceDetails = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_ATTENDANCE_COUNT_REQUEST });

    const { data } = await axios.get('/attendance-count');

    dispatch({
      type: types.LOAD_ATTENDANCE_COUNT_SUCCESS,
      payload: data.result,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_ATTENDANCE_COUNT_FAIL,
      payload: error,
    });
  }
};

//AD
export const countADDetails = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_AD_COUNT_REQUEST });

    const { data } = await axios.get('/arrival-departure-count');

    dispatch({
      type: types.LOAD_AD_COUNT_SUCCESS,
      payload: data.result,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_AD_COUNT_FAIL,
      payload: error,
    });
  }
};

export const leaveTotalCount = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_CURENT_LEAVE_REQUEST });

    const { data } = await axios.get('/current-leave-staff');

    dispatch({
      type: types.LOAD_CURENT_LEAVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_CURENT_LEAVE_FAIL,
      payload: error,
    });
  }
};

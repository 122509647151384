import React, { useMemo } from 'react';

function NumberInput({
  name,
  label,
  min,
  max,
  values,
  errors,
  touches,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  className,
  step,
}) {
  const isInvalid = useMemo(() => {
    return touches[name] && errors[name];
  }, [touches, errors, name]);

  return (
    <>
      <label
        htmlFor={name}
        className='block text-[11px] mb-2 font-bold text-sky-900'
      >
        {label}
      </label>

      <input
        id={name}
        name={name}
        type='number'
        min={min}
        max={max}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        placeholder={placeholder}
        step={step}
        defaultValue={values[name]}
        onWheel={(e) => e.target.blur()}
        className={
          className
            ? className
            : 'flex items-center w-full px-2 h-9 py-4 border text-xs border-[#f9f8fc] rounded-md'
        }
      />
      {isInvalid && (
        <p className='text-red-500 font-[600] text-[10px] mt-1'>
          {errors[name]}
        </p>
      )}
    </>
  );
}

export default NumberInput;

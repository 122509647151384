import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import {
  permission as usePermission,
  isAuthorized as useAuthorized,
} from 'store/auth/selector';
import { LazyPage, NoMatch } from 'components/base';
import Dashboard from 'layouts';
import routeItems from './routes';

//routes import
import * as authRoutes from 'constants/Routes';

function Router() {
  const isAuthorized = useSelector(useAuthorized);

  const permission = useSelector(usePermission);

  let location = useLocation();

  const PrivateRoute = ({ children }) => {
    if (!isAuthorized) {
      return (
        <Navigate to={authRoutes.LOGIN} state={{ from: location }} replace />
      );
    }

    return children;
  };

  return (
    <Routes>
      <Route path={authRoutes.LOGIN} element={<LazyPage page='Login' />} />
      <Route
        path='/'
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      >
        {routeItems.map((route, key) => {
          const isPermission = route?.policy
            ? permission?.some((item) => item?.description === route?.policy)
            : true;

          return (
            <Route
              path={route?.path}
              index
              key={key}
              element={
                isPermission ? (
                  <LazyPage page={route?.component} />
                ) : (
                  <LazyPage page='UnAuthorized' />
                )
              }
            />
          );
        })}
      </Route>

      <Route
        path={authRoutes.LEAVE_APPROVAL}
        element={<LazyPage page='Reports/Leave/Approval' />}
      />

      <Route path='*' element={<NoMatch />} />
    </Routes>
  );
}

export default Router;

//title
export const LOAD_EVENT_CALENDER_VIEW_REQUEST = 'LOAD_EVENT_CALENDER_VIEW_REQUEST';
export const LOAD_EVENT_CALENDER_VIEW_SUCCESS = 'LOAD_EVENT_CALENDER_VIEW_SUCCESS';
export const LOAD_EVENT_CALENDER_VIEW_FAIL = 'LOAD_EVENT_CALENDER_VIEW_FAIL';


export const LOAD_STAFF_COUNT_REQUEST = 'LOAD_STAFF_COUNT_REQUEST';
export const LOAD_STAFF_COUNT_SUCCESS = 'LOAD_STAFF_COUNT_SUCCESS';
export const LOAD_STAFF_COUNT_FAIL = 'LOAD_STAFF_COUNT_FAIL';


export const LOAD_LEAVE_COUNT_REQUEST = 'LOAD_LEAVE_COUNT_REQUEST';
export const LOAD_LEAVE_COUNT_SUCCESS = 'LOAD_LEAVE_COUNT_SUCCESS';
export const LOAD_LEAVE_COUNT_FAIL = 'LOAD_LEAVE_COUNT_FAIL';

export const LOAD_ATTENDANCE_COUNT_REQUEST = 'LOAD_ATTENDANCE_COUNT_REQUEST';
export const LOAD_ATTENDANCE_COUNT_SUCCESS = 'LOAD_ATTENDANCE_COUNT_SUCCESS';
export const LOAD_ATTENDANCE_COUNT_FAIL = 'LOAD_ATTENDANCE_COUNT_FAIL';

export const LOAD_EVENT_COUNT_REQUEST = 'LOAD_EVENT_COUNT_REQUEST';
export const LOAD_EVENT_COUNT_SUCCESS = 'LOAD_EVENT_COUNT_SUCCESS';
export const LOAD_EVENT_COUNT_FAIL = 'LOAD_EVENT_COUNT_FAIL';

export const LOAD_AD_COUNT_REQUEST = 'LOAD_AD_COUNT_REQUEST';
export const LOAD_AD_COUNT_SUCCESS = 'LOAD_AD_COUNT_SUCCESS';
export const LOAD_AD_COUNT_FAIL = 'LOAD_AD_COUNT_FAIL';


export const LOAD_CURENT_LEAVE_REQUEST = 'LOAD_CURENT_LEAVE_REQUEST';
export const LOAD_CURENT_LEAVE_SUCCESS = 'LOAD_CURENT_LEAVE_SUCCESS';
export const LOAD_CURENT_LEAVE_FAIL = 'LOAD_CURENT_LEAVE_FAIL';

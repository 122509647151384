//title
export const LOAD_STAFFS_REQUEST = 'LOAD_STAFFS_REQUEST';
export const LOAD_STAFFS_SUCCESS = 'LOAD_STAFFS_SUCCESS';
export const LOAD_STAFFS_FAIL = 'LOAD_STAFFS_FAIL';

export const LOAD_STAFF_REQUEST = 'LOAD_STAFF_REQUEST';
export const LOAD_STAFF_SUCCESS = 'LOAD_STAFF_SUCCESS';
export const LOAD_STAFF_FAIL = 'LOAD_STAFF_FAIL';

export const LOAD_STAFF_DOCUMENT_REQUEST = 'LOAD_STAFF_DOCUMENT_REQUEST';
export const LOAD_STAFF_DOCUMENT_SUCCESS = 'LOAD_STAFF_DOCUMENT_SUCCESS';
export const LOAD_STAFF_DOCUMENT_FAIL = 'LOAD_STAFF_DOCUMENT_FAIL';

export const DELETE_STAFF_DOCUMENT_REQUEST = 'DELETE_STAFF_DOCUMENT_REQUEST';
export const DELETE_STAFF_DOCUMENT_SUCCESS = 'DELETE_STAFF_DOCUMENT_SUCCESS';
export const DELETE_STAFF_DOCUMENT_FAIL = 'DELETE_STAFF_DOCUMENT_FAIL';

export const LOAD_ALL_STAFFS_REQUEST = 'LOAD_ALL_STAFFS_REQUEST';
export const LOAD_ALL_STAFFS_SUCCESS = 'LOAD_ALL_STAFFS_SUCCESS';
export const LOAD_ALL_STAFFS_FAIL = 'LOAD_ALL_STAFFS_FAIL';

export const LOAD_STAFFS_BY_CATEGORY_REQUEST =
  'LOAD_STAFFS_BY_CATEGORY_REQUEST';
export const LOAD_STAFFS_BY_CATEGORY_SUCCESS =
  'LOAD_STAFFS_BY_CATEGORY_SUCCESS';
export const LOAD_STAFFS_BY_CATEGORY_FAIL = 'LOAD_STAFFS_BY_CATEGORY_FAIL';
